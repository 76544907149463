import { DateTime } from 'luxon';

export function stringifyIdleConfirmationValue(secondsLeft: number) {
  if (secondsLeft < 60) {
    return `${secondsLeft} sec`;
  }

  if (secondsLeft < 60 * 60) {
    const minutes = Math.floor(secondsLeft / 60);
    const seconds = secondsLeft % 60;
    return `${minutes} min ${seconds < 10 ? '0' : ''}${seconds} sec`;
  }

  return 'about an hour';
}

export function formatDate(datetime: string) {
  const d = DateTime.fromSQL(datetime);

  if (!d.isValid) {
    return '';
  }

  return d.toFormat('yyyy/MM/dd');
}

export function formatFullDateAnd24Hr(datetime: string) {
  const d = DateTime.fromSQL(datetime);

  if (!d.isValid) {
    return '';
  }

  return d.toFormat("MMMM d, yyyy 'at' HH:mm");
}
