import React, { useEffect, useState } from 'react';
import { Button } from 'ui';

import { ReactComponent as IconBug } from './icons/icon-report-flag.svg';
import { ReactComponent as IconFeedback } from './icons/icon-feedback-thumbs-up.svg';

const IssueLogger = () => {
  const [hasLoadedJiraCollectorFeedback, setHasLoadedJiraCollectorFeedback] =
    useState(false);
  const [hasLoadedJiraCollectorBug, setHasLoadedJiraCollectorBug] =
    useState(false);

  useEffect(() => {
    const hasLoaded = !!(window as any).JIRA_COLLECTOR?.showCollectorDialog;
    setHasLoadedJiraCollectorFeedback(hasLoaded);
    const hasLoadedBug = !!(window as any).JIRA_COLLECTOR
      ?.showCollectorDialogReportBug;
    setHasLoadedJiraCollectorBug(hasLoadedBug);
  }, []);

  if (!hasLoadedJiraCollectorFeedback && !hasLoadedJiraCollectorBug) {
    return null;
  }

  return (
    <section className="settings-section settings-log-issue">
      <h3>Feedback</h3>
      <div className="button-list">
        {hasLoadedJiraCollectorBug && (
          <Button
            type="text"
            id="jira-collector-bug-button"
            icon={<IconBug />}
            onClick={() => {
              (window as any).JIRA_COLLECTOR.showCollectorDialogReportBug();
            }}
          >
            Report a bug
          </Button>
        )}
        {hasLoadedJiraCollectorFeedback && (
          <Button
            type="text"
            id="jira-collector-feedback-button"
            icon={<IconFeedback />}
            onClick={() => {
              (window as any).JIRA_COLLECTOR.showCollectorDialog();
            }}
          >
            Request enhancement
          </Button>
        )}
      </div>
    </section>
  );
};

export default IssueLogger;
