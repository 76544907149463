import { FC, ReactNode } from 'react';

import './tag.css';

type TagProps = {
  type?: 'default' | 'bubble';
  backgroundColor?: string;
  textColor?: 'light' | 'dark';
  size?: 'small' | 'medium' | 'large';
  children: ReactNode;
};

export const Tag: FC<TagProps> = ({
  type = 'default',
  backgroundColor = 'gray',
  textColor = 'light',
  size = 'small',
  children,
}) => {
  return (
    <div
      data-testid="tag"
      className={`tag type-${type} bg-${backgroundColor} text-${textColor} size-${size}`}
    >
      {children}
    </div>
  );
};
