import { Store } from 'pullstate';

import { mapConversationHistoryToTimeBlocks } from 'utils/history-mapper';
import ApiService from 'services/api';
import { CONVERSATION_PAGING_SIZE } from './metadata';
import { Conversation } from 'ui/organisms/ConversationHistory';
import { getUniqueObjectPropertyInArray } from 'utils/array-utils';

type ConversationHistoryStoreType = {
  id: number;
  title: string;
  history: Conversation[];
  hasError: boolean;
};

const initialState: {
  hasLoaded: boolean;
  history: ConversationHistoryStoreType[];
  hasError: boolean;
  totalConversations: number;
  currentPage: number;
  conversations: Conversation[];
  hasReachedLastPage: boolean;
} = {
  hasLoaded: false,
  history: [],
  hasError: false,
  totalConversations: 0,
  currentPage: 1,
  conversations: [],
  hasReachedLastPage: false,
};

export const ConversationHistoryStore = new Store(initialState);

export const resetStore = () => {
  ConversationHistoryStore.update((s) => {
    return initialState;
  });
};

export const loadConversationHistory = async () => {
  let conversations, totalCount;
  try {
    [conversations, totalCount] = await ApiService.getConversations({
      top: CONVERSATION_PAGING_SIZE,
    });
  } catch (e) {
    return ConversationHistoryStore.update((s) => {
      return {
        ...s,
        hasLoaded: true,
        hasError: true,
      };
    });
  }
  if (!conversations) {
    return ConversationHistoryStore.update((s) => {
      return {
        ...s,
        hasLoaded: true,
      };
    });
  }

  return ConversationHistoryStore.update((s) => {
    return {
      ...s,
      hasLoaded: true,
      hasError: false,
      totalConversations: Number(totalCount),
      conversations: conversations,
      currentPage: 2,
    };
  });
};

export const loadAdditionalConversations = async () => {
  const currentPage = ConversationHistoryStore.getRawState().currentPage;
  const currentTotal =
    ConversationHistoryStore.getRawState().totalConversations;

  if (
    currentPage * CONVERSATION_PAGING_SIZE >
    Number(currentTotal) + CONVERSATION_PAGING_SIZE
  ) {
    return ConversationHistoryStore.update((s) => {
      return {
        ...s,
        hasReachedLastPage: true,
      };
    });
  }

  let conversations, totalCount;
  try {
    [conversations, totalCount] = await ApiService.getConversations({
      top: CONVERSATION_PAGING_SIZE,
      skip: (currentPage - 1) * CONVERSATION_PAGING_SIZE,
    });
  } catch (e) {
    return ConversationHistoryStore.update((s) => {
      return {
        ...s,
        hasLoaded: true,
        hasError: true,
      };
    });
  }

  ConversationHistoryStore.update((s) => {
    return {
      ...s,
      hasLoaded: true,
      hasError: false,
      totalConversations: totalCount,
      conversations: [...s.conversations, ...conversations],
      currentPage: s.currentPage + 1,
    };
  });
};

ConversationHistoryStore.createReaction(
  (s) => s.conversations,
  (conversations, draft) => {
    const uniqueConversations = getUniqueObjectPropertyInArray(conversations);
    draft.history = mapConversationHistoryToTimeBlocks(
      uniqueConversations,
    ) as any;
  },
);
