import { useState } from 'react';
import { Button, Modal } from 'ui/atoms';

import './conversation-response-sources.css';
import SourceItem from './source-item';

function getPluralizedWord(length) {
  if (length === 1) {
    return 'source';
  }
  return 'sources';
}

const ConversationResponseSources = ({ sources }) => {
  const [openSources, setOpenSources] = useState(false);
  return (
    <>
      <div className="conversation-response-sources">
        <Button
          type="text"
          className="sources-button"
          onClick={() => {
            setOpenSources(!openSources);
          }}
        >
          {sources.length} {getPluralizedWord(sources.length)}
        </Button>
      </div>
      <Modal
        open={openSources}
        title={'Sources used in response'}
        footer={[]}
        className="conversation-response-source-modal"
        onCancel={() => {
          setOpenSources(false);
        }}
      >
        <div className="conversation-response-source-list">
          {sources.map((source) => {
            return (
              <SourceItem
                key={`source${source.displayName}`}
                source={source}
              ></SourceItem>
            );
          })}
        </div>
      </Modal>
    </>
  );
};

export default ConversationResponseSources;
