import { Modal, Button } from 'ui/atoms';
import { getIndexFileAndSiteSummaryLabel } from 'ui/molecules/IndexChit';
import { formatFullDateAnd24Hr } from 'utils/time-utils';

import { ReactComponent as IconBackArrow } from './icon-back-arrow.svg';

import './resource-detail-modal.css';

export const ResourceDetailModal = ({
  resource,
  showResourceDetailModal,
  setShowResourceDetailModal,
  setFileSelectorVisible,
}) => {
  const getResourceLabel = (resource) => {
    if (!resource?.itemType) {
      return '';
    }

    return (
      resource.itemType.charAt(0).toUpperCase() + resource.itemType.slice(1)
    );
  };

  const resourceTypeLabel = getResourceLabel(resource);

  return (
    <Modal
      title={`${resourceTypeLabel} info`}
      open={showResourceDetailModal}
      mask={false}
      footer={false}
      closable={true}
      onCancel={() => {
        setShowResourceDetailModal(false);
        setFileSelectorVisible(false);
      }}
    >
      <div
        data-testid="resource-detail-modal"
        className="resource-detail-modal"
      >
        <Button
          aria-label="Back"
          className="ant-modal-close overtake-back-button"
          icon={<IconBackArrow />}
          onClick={() => setShowResourceDetailModal(false)}
        ></Button>
        <div className="primary-details">
          <h1>{resource.displayFileName}</h1>
          <h3>{resourceTypeLabel}</h3>
          <p>
            {getIndexFileAndSiteSummaryLabel({
              numFiles: resource.numFiles,
              numWebsites: resource.numWebsites,
            })}
          </p>
        </div>

        <div className="detail-section">
          <strong>{resourceTypeLabel} description</strong>
          <p>{resource.description}</p>
        </div>

        <div className="detail-section">
          <strong>Information</strong>
          <div className="datetime-row">
            <span className="datetime-row-type">Created</span>
            <span className="datetime-row-date">
              {formatFullDateAnd24Hr(resource.datetimeCreated)}
            </span>
          </div>
          <div className="datetime-row">
            <span className="datetime-row-type">Modified</span>
            <span className="datetime-row-date">
              {formatFullDateAnd24Hr(resource.datetimeLastUpdated)}
            </span>
          </div>
        </div>
      </div>
    </Modal>
  );
};
