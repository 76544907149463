import React from 'react';
import classnames from 'classnames';

import { Tag } from 'ui/atoms/Tag';

import { ReactComponent as IconCircle } from './circle.svg';
import { ReactComponent as IconCircleCheck } from './circle-check.svg';

import './context-button.css';

const ContextButton = ({
  title,
  text,
  value,
  isSelected,
  setSelected,
  disabled = false,
  showTitleTag = '',
}) => {
  const handleCheckboxChange = () => {
    if (disabled || !setSelected) {
      return;
    }

    return setSelected(value);
  };

  return (
    <div
      data-testid="context-button"
      className={classnames(
        'context-button',
        { selected: isSelected },
        { disabled: disabled },
      )}
      onClick={handleCheckboxChange}
    >
      <div className="context-button-body">
        <h2 className="context-button-title">
          {title}
          {showTitleTag && (
            <div className="context-button-title-tag">
              <Tag type="default">{showTitleTag}</Tag>
            </div>
          )}
        </h2>
        <p>{text}</p>
      </div>
      {isSelected ? <IconCircleCheck /> : <IconCircle />}
    </div>
  );
};

export default ContextButton;
