export const getUniqueObjectPropertyInArray = (arr, property = 'id') => {
  if (!Array.isArray(arr)) {
    return arr;
  }

  const uniqueInArray = new Set();

  return arr.filter((item) => {
    if (uniqueInArray.has(item[property])) {
      return false;
    }

    uniqueInArray.add(item[property]);
    return true;
  });
};
