import React, { useEffect, useRef, useState } from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';

import {
  AppContextStore,
  setIsSidebarCollapsed,
  setTheme,
} from 'stores/app-context';
import { Conversation, Drawer, Button, Loading } from 'ui';
import ConversationHistory from 'ui/organisms/ConversationHistory';
import Settings from 'ui/organisms/Settings';
import GoldieLogo from 'ui/molecules/GoldieLogo';
import {
  ConversationHistoryStore,
  loadAdditionalConversations,
  loadConversationHistory,
} from 'stores/conversation-history';
import {
  setCurrentConversation,
  setGptModel,
} from 'stores/current-conversation';
import { getFallbackModelName, UserStore } from 'stores/user';
import { CONVERSATION_PAGING_SIZE, initMetadata } from 'stores/metadata';
import { useDebounceCallback } from 'usehooks-ts';

import { ReactComponent as IconCollapse } from './icon-collapse.svg';
import { ReactComponent as IconPlus } from './icon-plus.svg';

import './home.css';

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const userHasLoaded = UserStore.useState((s) => s.userHasLoaded);
  const isCollapsed = AppContextStore.useState((s) => s.isSidebarCollapsed);
  const { history, historyHasLoaded, totalConversations, hasReachedLastPage } =
    ConversationHistoryStore.useState((s) => ({
      history: s.history,
      historyHasLoaded: s.hasLoaded,
      totalConversations: s.totalConversations,
      hasReachedLastPage: s.hasReachedLastPage,
    }));
  const isMessagesScrolling = AppContextStore.useState(
    (s) => s.isMessagesWindowScrolling,
  );
  const conversationHistoryRef = useRef<HTMLDivElement>(null);
  const [conversationsAreLoading, setConversationsAreLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (userHasLoaded) {
      loadConversationHistory();
      initMetadata();
    }
  }, [userHasLoaded]);

  useEffect(() => {
    document.body.classList.toggle('drawer-open', isCollapsed);
  }, [isCollapsed]);

  const debounceLoadingConversations = useDebounceCallback(() => {
    loadAdditionalConversations();
    setConversationsAreLoading(false);
  }, 500);

  useEffect(() => {
    const element = conversationHistoryRef.current;

    const handleConverastionsScroll = () => {
      if (!element) {
        return;
      }

      const { scrollTop, scrollHeight, clientHeight } = element;

      if (scrollTop + clientHeight >= scrollHeight - 15) {
        setConversationsAreLoading(true);
        debounceLoadingConversations();
      }
    };

    if (totalConversations <= CONVERSATION_PAGING_SIZE) {
      return;
    }

    if (element) {
      element.addEventListener('scroll', handleConverastionsScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', handleConverastionsScroll);
      }
    };
  }, [
    conversationHistoryRef,
    totalConversations,
    debounceLoadingConversations,
  ]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <main
      data-testid="home-container"
      className={classNames(['home', { '-collapsed': isCollapsed }])}
    >
      <Drawer isCollapsed={isCollapsed}>
        <div className="logo-container">
          <GoldieLogo />
          <button
            data-testid="drawer-collapse-button"
            aria-label="Collapse Menu"
            className="collapse-control -mobile"
            onClick={() => setIsSidebarCollapsed(!isCollapsed)}
          >
            <IconCollapse />
          </button>
        </div>
        <div className="new-conversation-button">
          <Button
            type="outline"
            icon={<IconPlus />}
            onClick={() => {
              setGptModel(getFallbackModelName());
              setCurrentConversation();
            }}
          >
            New chat
          </Button>
        </div>
        <div className="conversation-histories" ref={conversationHistoryRef}>
          {!historyHasLoaded && <Loading />}
          {historyHasLoaded && history && history.length > 0 && (
            <>
              {history.map((h) => {
                if (!h.history.length) {
                  return null;
                }

                return (
                  <ConversationHistory
                    key={`cconversation_history_${h.id}`}
                    title={h.title}
                    list={h.history}
                  />
                );
              })}
            </>
          )}
          {!hasReachedLastPage && (
            <div className="paginated-loading-conversations">
              {conversationsAreLoading && <Spin size="small" />}
            </div>
          )}
        </div>
      </Drawer>
      <article
        id="main-content"
        className={`${isMessagesScrolling ? '-scrolling' : ''}`}
      >
        <Settings setTheme={setTheme}>
          <button
            data-testid="drawer-collapse-button"
            aria-label="Collapse Menu"
            className="collapse-control"
            onClick={() => setIsSidebarCollapsed(!isCollapsed)}
          >
            <IconCollapse />
          </button>
        </Settings>

        <Conversation />
      </article>
    </main>
  );
};

export default Home;
