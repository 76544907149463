import { Button } from 'ui/atoms';
import { openPublicFile } from 'utils/open-public-file';

import './phi-protection.css';

const PHIProtection = ({ type = 'default' }) => {
  return (
    <div className="phi-protection">
      {type === 'default' && <PHIDefault />}

      {type === 'medical' && <PHIMedicalAdvice />}

      {type === 'jailbreak' && <PHIJailbreak />}
    </div>
  );
};

export default PHIProtection;

const PHIDefault = () => {
  return (
    <>
      <h3>Personal health information (PHI) may by present in your prompt.</h3>
      PHI is not permitted in Goldie.
      <ul>
        <li>If this prompt does include PHI, it should be deleted.</li>
        <li>
          If you generate a response, the development team will have access to
          the response to your prompt.
        </li>
      </ul>
      <PHIResolutionButtons />
    </>
  );
};

const PHIMedicalAdvice = () => {
  return (
    <>
      <h3>It seems like you&#39;re asking for medical advice</h3>
      Asking for medical advice is not permitted in Goldie.
      <ul>
        <li>
          If you are asking for medical advice, your prompt should be deleted.
        </li>
        <li>
          If you generate a response, the development team will have access to
          the response to your prompt.
        </li>
      </ul>
      <PHIResolutionButtons />
    </>
  );
};

const PHIJailbreak = () => {
  return (
    <>
      <h3>It seems like you&#39;re trying to jailbreak Goldie.</h3>
      Jailbreaking is strictly against Goldie&#39;s&nbsp;
      <Button
        type="text"
        className="button-inline-link no-color"
        onClick={() => openPublicFile('goldie-terms-of-use.pdf')}
      >
        terms of use.
      </Button>
      <ul>
        <li>
          If you are trying to jailbreak Goldie, your prompt should be deleted.
        </li>
        <li>
          If you generate a response, the development team will have access to
          the response to your prompt.
        </li>
      </ul>
      <PHIResolutionButtons />
    </>
  );
};

const PHIResolutionButtons = () => {
  return (
    <>
      <Button
        type="text"
        className="button-inline-link no-color"
        onClick={() => openPublicFile('goldie-privacy-notice.pdf')}
      >
        Goldie's privacy notice
      </Button>
      <div className="PHI-resolution-buttons">
        <Button
          type="secondary"
          onClick={() => {
            console.log('deleting prompt');
          }}
        >
          Delete prompt
        </Button>

        <Button
          type="outline"
          onClick={() => {
            console.log('generate response');
          }}
        >
          Generate response anyways
        </Button>
      </div>
    </>
  );
};
