import { FileChit } from 'ui/molecules/FileChit';
import { IndexChit } from 'ui/molecules/IndexChit';
import { MAX_FILE_CHITS_VIEWABLE } from 'stores/metadata';
import { FileType, IndexType } from 'utils/file-utils';

import { ReactComponent as FileChitIconOverflow } from './icon-overflow.svg';

import './file-chit-row.css';

export type FileChitRowProps = {
  size?: 'default' | 'small';
  files: FileType[];
  indexes?: IndexType[];
  limit?: number;
  onSelectFileClick?: any;
  isEditable?: boolean;
};

export const FileChitRow = ({
  size = 'default',
  files = [],
  indexes = [],
  limit = MAX_FILE_CHITS_VIEWABLE,
  onSelectFileClick,
  isEditable = false,
}: FileChitRowProps) => {
  if (!files.length && !indexes.length) {
    return <></>;
  }

  const filesAndIndexes = [...files, ...indexes];

  let fileChitsToDisplay: (FileType | IndexType)[];

  const shouldDisplayOverflowChit = files.length + indexes.length > limit;

  if (shouldDisplayOverflowChit) {
    fileChitsToDisplay = filesAndIndexes.slice(0, limit);
  } else {
    fileChitsToDisplay = filesAndIndexes;
  }

  return (
    <section data-testid="file-chit-row" className={`file-chit-row ${size}`}>
      {fileChitsToDisplay.map((file) => {
        const isFile = 'privacyLevel' in file;
        return !isFile ? (
          <IndexChit
            key={crypto.randomUUID()}
            index={file}
            isEditable={isEditable}
          />
        ) : (
          <FileChit
            key={crypto.randomUUID()}
            file={file}
            isEditable={isEditable}
          />
        );
      })}

      {shouldDisplayOverflowChit && (
        <OverflowChit
          onSelectFileClick={onSelectFileClick}
          amount={Math.abs(filesAndIndexes.length - limit)}
        />
      )}
    </section>
  );
};

export const OverflowChit = ({ amount, onSelectFileClick }) => {
  return (
    <button
      className="file-chit"
      data-testid="overflow-chit"
      onClick={onSelectFileClick}
    >
      <div className="file-icon processing">
        <FileChitIconOverflow />
      </div>
      <div className="file-information">
        <div className="file-name">{amount} more</div>
      </div>
    </button>
  );
};
