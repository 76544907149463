import { Button, Badge } from 'ui';
import { UserStore } from 'stores/user';
import { CurrentConversationStore } from 'stores/current-conversation';

import { ReactComponent as IconFile } from './file.svg';
import { ReactComponent as IconUpload } from './upload.svg';

import './conversation-controls.css';
import { formatFileCount } from 'utils/string-utils';

const ConversationControls = ({ onSelectFileClick, onFileUploadClick }) => {
  const documentsRemaining = UserStore.useState((s) => s.documentsRemaining);
  const filesSelected = CurrentConversationStore.useState(
    (s) => s.fileStats?.valid || 0,
  );

  return (
    <div className="conversation-controls">
      <Badge count={formatFileCount(filesSelected)}>
        <Button
          type="secondary"
          data-testid="test-select-file-button"
          className="select-files-btn"
          onClick={onSelectFileClick}
        >
          <IconFile /> Select sources
        </Button>
      </Badge>

      <Button
        type="secondary"
        className="upload-btn"
        data-testid="test-file-upload-button"
        onClick={onFileUploadClick}
        disabled={documentsRemaining === 0}
      >
        <IconUpload /> Upload file
      </Button>
    </div>
  );
};

export default ConversationControls;
