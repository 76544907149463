import { Store } from 'pullstate';
import api from 'services/api';
import { logger } from 'services/logs/logger';
import { AccessLevel } from 'stores/metadata';

type FileUploadStoreType = {
  isValid: boolean;
  isUploading: boolean;
  startUpload: boolean;
  hasFile: boolean;
  uploadComplete: boolean;
  progress?: number;
  fileName: string;
  status: string;
  accessLevel: AccessLevel;
  organization?: string;
  product?: string;
  hasError: boolean;
  errorMessage?: string;
  fileId?: string;
  datetimeLastUpdated?: string;
};

const initialState: FileUploadStoreType = {
  isValid: false,
  isUploading: false,
  startUpload: false,
  hasFile: false,
  uploadComplete: false,
  progress: undefined,
  fileName: '',
  status: 'Pending',
  accessLevel: AccessLevel.Internal,
  organization: undefined,
  product: undefined,
  hasError: false,
  errorMessage: undefined,
  fileId: undefined,
};

export const FileUploadStore = new Store(initialState);

FileUploadStore.createReaction(
  (s) => s.progress,
  (progress, draft) => {
    if (progress) {
      if (progress === 0) {
        draft.status = 'Pending';
      } else if (progress < 100) {
        draft.isUploading = true;
        draft.status = 'Uploading';
      } else {
        draft.status = 'Complete';
        draft.uploadComplete = true;
        draft.isUploading = false;
      }
    }
  },
);

FileUploadStore.createReaction(
  (s) => s.hasFile,
  (hasFile, draft) => {
    if (draft.accessLevel === AccessLevel.Personal) {
      draft.isValid = hasFile;
    } else if (draft.accessLevel === AccessLevel.Internal) {
      draft.isValid = hasFile;
    } else {
      draft.isValid = hasFile && !!draft.organization && !!draft.product;
    }
  },
);

FileUploadStore.createReaction(
  (s) => s.organization,
  (organization, draft) => {
    if (draft.accessLevel === AccessLevel.Public) {
      draft.isValid = draft.hasFile && !!organization && !!draft.product;
    }
  },
);

FileUploadStore.createReaction(
  (s) => s.product,
  (product, draft) => {
    if (draft.accessLevel === AccessLevel.Public) {
      draft.isValid = draft.hasFile && !!draft.organization && !!product;
    }
  },
);

FileUploadStore.createReaction(
  (s) => s.accessLevel,
  (accessLevel, draft) => {
    if (accessLevel === AccessLevel.Personal) {
      draft.isValid = draft.hasFile;
    } else if (draft.accessLevel === AccessLevel.Internal) {
      draft.isValid = draft.hasFile;
    } else {
      draft.isValid = draft.hasFile && !!draft.organization && !!draft.product;
    }
  },
);

export const setProgress = (progress) => {
  FileUploadStore.update((s) => {
    s.progress = progress;
    return s;
  });
};

export const setFileId = (fileId, datetimeLastUpdated) => {
  FileUploadStore.update((s) => {
    s.fileId = fileId;
    s.datetimeLastUpdated = datetimeLastUpdated;
    return s;
  });
};

export const setStartUpload = (startUpload) => {
  FileUploadStore.update((s) => {
    s.startUpload = startUpload;
    return s;
  });
};

export const resetStore = () => {
  FileUploadStore.update((s) => {
    return initialState;
  });
};

export const setAccessLevel = (accessLevel) => {
  FileUploadStore.update((s) => {
    s.accessLevel = accessLevel;
    return s;
  });
};

export const setOrganization = (organization) => {
  FileUploadStore.update((s) => {
    s.organization = organization;
  });
};

export const setProduct = (product) => {
  FileUploadStore.update((s) => {
    s.product = product;
  });
};

export const setFileInfo = (file) => {
  FileUploadStore.update((s) => {
    s.hasFile = true;
    s.progress = 0;
    s.fileName = file.name;
    s.status = 'Pending';
    return s;
  });
};

export const setError = (errorMessage) => {
  FileUploadStore.update((s) => {
    s.hasError = true;
    s.errorMessage = errorMessage;
    return s;
  });
};

export const clearError = () => {
  FileUploadStore.update((s) => {
    return {
      ...initialState,
      accessLevel: s.accessLevel,
      organization: s.organization,
      product: s.product,
    };
  });
};

export const saveMetadata = async () => {
  const s = FileUploadStore.getRawState();
  const fileInfo = await api.getFileInfo(s.fileId); // Get latest datetimeLastUpdated

  return api.updateFileMetadata(s.fileId, fileInfo.datetimeLastUpdated, {
    privacyLevel: s.accessLevel,
    associatedOrganization: s.organization,
    associatedProduct: s.product,
  });
};

export const deleteFile = async (fileId) => {
  await api.deleteFile(fileId).catch((e) => {
    logger.error(e);
  });
  FileUploadStore.update((s) => {
    s.hasFile = initialState.hasFile;
    s.isUploading = false;
    s.progress = 0;
    s.fileName = initialState.fileName;
    s.status = initialState.status;
    s.uploadComplete = initialState.uploadComplete;
    return s;
  });
};
