import { useState } from 'react';
import { Button, Modal } from 'ui/atoms';
import { ReactComponent as IconAnonymization } from './icons/icon-anonymization.svg';
import { ReactComponent as IconBack } from './icons/icon-back.svg';
import { anonymizeUser } from 'stores/user';
import { setGlobalError } from 'stores/global-message';
import { openPublicFile } from 'utils/open-public-file';

const OtherOptionsFooter = (setToggleConfirm, logout) => {
  return (
    <>
      <Button
        type="outline"
        onClick={() => {
          setToggleConfirm(false);
        }}
      >
        Cancel
      </Button>
      <Button
        onClick={() => {
          (async () => {
            const result = await anonymizeUser();
            if (result) {
              logout();
            } else {
              setGlobalError('Unable to anonmyze this account');
            }
          })();
        }}
      >
        Submit
      </Button>
    </>
  );
};

const OtherOptions = ({ logout }) => {
  const [toggleConfirm, setToggleConfirm] = useState(false);
  return (
    <>
      <section className="settings-section settings-other">
        <h3>Other</h3>
        <div className="button-list">
          <Button
            type="text"
            id="jira-collector-feedback-button"
            icon={<IconAnonymization />}
            onClick={() => {
              setToggleConfirm(true);
            }}
          >
            Erase my personal data and deactivate my account
          </Button>
        </div>
      </section>
      <Modal
        title="Confirm data anonymization"
        className="confirm-anonymization-modal"
        open={toggleConfirm}
        onCancel={() => {
          setToggleConfirm(false);
        }}
        footer={OtherOptionsFooter(setToggleConfirm, logout)}
      >
        <Button
          type="text"
          shape="circle"
          className="back-button"
          onClick={() => {
            setToggleConfirm(false);
          }}
        >
          <IconBack />
        </Button>
        <p>
          By pressing <span className="text-highlight">submit</span>, you
          acknowledge the following:
        </p>
        <ul>
          <li>
            Your account will be immediately deactivated and certain personal
            information will be removed from the platform in accordance with the{' '}
            <Button
              type="text"
              className="inline-button"
              onClick={() => openPublicFile('goldie-terms-of-use.pdf')}
            >
              Goldie Terms of Use{' '}
            </Button>{' '}
            and{' '}
            <Button
              type="text"
              className="inline-button"
              onClick={() => openPublicFile('goldie-privacy-notice.pdf')}
            >
              Privacy Notice
            </Button>
            . If you wish to regain access at any point after pressing submit,
            you will need to re-request access following the current procedure.
          </li>
          <li>
            Your files marked as “Private” and any associated prompts in these
            chats will be permanently deleted from the platform.
          </li>
          <li>
            Your files marked as “Development review and Model training” and any
            associated prompts in these chats will no longer be associated with
            your account.
          </li>
        </ul>
        <p>
          Please note that content deletion requests may be limited and will not
          include content that Stryker is required by law to retain or has a
          compelling interest to retain.
        </p>
      </Modal>
    </>
  );
};

export default OtherOptions;
